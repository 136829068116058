@import 'variables';

.receiver-preview-avatar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: $receiver-preview-avatar-size;
  height: $receiver-preview-avatar-size;
  margin: auto 0;
  overflow: hidden;
  font-family: $font-family-monospace;
  background: $receiver-preview-avatar-bg;
  border-radius: $receiver-preview-avatar-border-radius;
  transition: $receiver-preview-avatar-transition;

  &.preview-avatar-sm {
    width: $receiver-preview-avatar-size-sm;
    height: $receiver-preview-avatar-size-sm;

    & .receiver-preview-name {
      color: $receiver-preview-avatar-color;
      font-size: $receiver-preview-avatar-font-size;
    }
  }

  .receiver-preview-username {
    color: $receiver-preview-avatar-username-color;
  }

  .receiver-preview-img {
    width: 100%;
    height: 100%;
  }

  .receiver-preview-name {
    margin: 0;
    color: $receiver-preview-avatar-name-color;
    font-weight: $receiver-preview-avatar-name-font-weight;
    font-size: $receiver-preview-avatar-name-font-size;
  }

  .receiver-preview-img + .receiver-preview-name {
    display: none;
  }
}
