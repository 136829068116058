@import 'variables';

.notification-blocks {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: rem-calc(580);
  margin: auto;
  padding: rem-calc(32) 0;
  text-align: center;
  border-radius: $notification-blocks-border-radius;

  .notification-blocks-icon {
    font-size: $notification-blocks-icon-font-size;

    &:before {
      color: $notification-blocks-before-color;
    }
  }

  @media (max-width: $grid-breakpoint-sm) {
    &__img {
      width: 100%;
      height: auto;
    }
  }
}

.notification-modal {
  .mat-dialog-content {
    max-height: 80vh;
  }
}
