@use './src/styles/variables' as *;
@use './src/styles/functions/functions' as *;

[data-theme='dark'] .counter-wrapper {
  background: #1b2028 !important;
  opacity: 1 !important;
}

[data-theme='dark'] .counter {
  color: #aaa9a9;
}

[data-theme='dark'] .counter-wrapper .lds-roller div {
  background: #aaa9a9;
}

/* warning in receiver for dark theme */
html[data-theme='dark'] .receiver-preview__warning {
  background-color: $warning-300;
  .ff-icon {
    color: $body-color !important;
  }
}

agm-map {
  height: 300px;
}

.fade-out-third {
  animation: fadeout-third 1s;
  -moz-animation: fadeout-third 1s; /* Firefox */
  -webkit-animation: fadeout-third 1s; /* Safari and Chrome */
  -o-animation: fadeout-third 1s; /* Opera */
}

@keyframes fadeout-third {
  from {
    opacity: 0.7;
  }
  to {
    opacity: 0;
  }
}

@-moz-keyframes fadeout-third {
  /* Firefox */
  from {
    opacity: 0.7;
  }
  to {
    opacity: 0;
  }
}

@-webkit-keyframes fadeout-third {
  /* Safari and Chrome */
  from {
    opacity: 0.7;
  }
  to {
    opacity: 0;
  }
}

@-o-keyframes fadeout-third {
  /* Opera */
  from {
    opacity: 0.7;
  }
  to {
    opacity: 0;
  }
}

.fade-in-third {
  animation: fadein-third 1s;
  -moz-animation: fadein-third 1s; /* Firefox */
  -webkit-animation: fadein-third 1s; /* Safari and Chrome */
  -o-animation: fadein-third 1s; /* Opera */
}

@keyframes fadein-third {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.7;
  }
}

@-moz-keyframes fadein-third {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 0.7;
  }
}

@-webkit-keyframes fadein-third {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 0.7;
  }
}

@-o-keyframes fadein-third {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 0.7;
  }
}

.fade-out {
  animation: fadeout 1s;
  -moz-animation: fadeout 1s; /* Firefox */
  -webkit-animation: fadeout 1s; /* Safari and Chrome */
  -o-animation: fadeout 1s; /* Opera */
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@-moz-keyframes fade-out {
  /* Firefox */
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@-webkit-keyframes fadeout {
  /* Safari and Chrome */
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@-o-keyframes fadeout {
  /* Opera */
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fade-in {
  animation: fadein 1s;
  -moz-animation: fadein 1s; /* Firefox */
  -webkit-animation: fadein 1s; /* Safari and Chrome */
  -o-animation: fadein 1s; /* Opera */
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.dropdown-item-disabled {
  color: lightgray !important;
}

.grecaptcha-badge {
  visibility: hidden;
}

.apple-pay {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.apple-pay__button {
  visibility: hidden;
  display: inline-block;
  min-width: 283px;
  min-height: 40px;
  border: 1px solid black;
  background-size: 100% calc(60% + 2px);
  background-repeat: no-repeat;
  background-color: white;
  background-position: 50% 50%;
  border-radius: 5px;
  padding: 0;
  margin: 5px auto;
  transition: background-color 0.15s;
}

.payment-container {
  &__apple-pay {
    &__button.visible {
      visibility: visible;
    }

    &__button:active {
      background-color: rgb(152, 152, 152);
    }
  }
}

//rearrange culturepicker position in header
.mat-button.culturepicker__button {
  padding: 0 !important;
  min-width: 34px;
}

//material dialog responsive
// TODO: transfer to design system
@media (max-width: $grid-breakpoint-md) {
  .full-width-dialog {
    max-width: 100vw !important;
  }
}

//Решение, чтобы растянуть окно с камерой на ширину модального окна
.webcam-wrapper {
  max-height: 65vh;
  overflow: hidden;

  video {
    display: block;
    width: 100%;
    height: 100%;
  }

  //Переопределение стилей кнопки поворота камеры
  .camera-switch {
    top: map-get($spacers, 2) !important;
    left: map-get($spacers, 2) !important;
    background-color: transparent !important;
  }
}

//For bottom sheet menu
.mat-bottom-sheet-container {
  border-radius: 20px 20px 0 0;
  padding: 0 0 !important;
}

// TODO воеменно, удалить после переноса в ДС
.locations-grid {
  .mat-expansion-panel-body {
    padding-left: 0;
    padding-right: 0;
  }
}

#fc_frame,
#fc_frame.fc-widget-normal {
  bottom: 55px !important;
}

// For validation in radio button
.validation-radio {
  .mat-radio-container .mat-radio-outer-circle {
    border-color: $danger !important;
  }
}

// Remove margin for select receiver in send
.custom-mat-select .mat-select-value .mat-select-value-text {
  margin-right: 0 !important;
}

@import 'styles/components/online_components';
