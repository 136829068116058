@import 'variables';

.modal-check {
  .modal-body {
    padding: rem-calc(20);

    .modal-title {
      color: $modal-check-title-color;
      font-weight: $modal-check-title-font-weight;
      font-size: $modal-check-title-font-size;
    }
  }
}
