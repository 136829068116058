@import 'variables';

// Modal Selfie FFO-767
.attachment-scanner {
  .modal-header {
    border-bottom: none;
  }

  // btn colors
  .modal-body {
    .attachment-scanner-btn {
      color: $attachment-scanner-btn-color !important;
    }

    .attachment-scanner-toolbar {
      color: $attachment-scanner-toolbar-color !important;

      .attachment-scanner-btn,
      .attachment-scanner-text {
        color: $attachment-scanner-btn-text-color !important;
      }
    }
  }

  // frames
  .attachment-scanner-selfie-frame {
    position: absolute;
    width: 100%;
    height: 100%;
    margin-top: 40px;
    background-image: url('data:image/svg+xml,' + $attachment-scanner-face-frame);
    background-repeat: no-repeat;
    background-position: $attachment-scanner-face-frame-bg-position;
  }

  .attachment-scanner-document-frame {
    position: absolute;
    width: 100%;
    height: 100%;
    margin-top: rem-calc(40);
    background-image: $attachment-scanner-document-frame-bg;
    background-repeat: no-repeat;
    background-position: 50% 30%;
    background-size: $attachment-scanner-document-frame-bg-size;
  }

  // Responsive layout
  @media (max-width: $grid-breakpoint-sm) {
    max-height: 95vh;
    overflow: hidden;

    .modal-header {
      display: block;
      padding: 0;
      text-align: center;

      & .close,
      & .btn-close {
        display: none;
      }

      & .attachment-scanner-heading {
        margin: 0;
        margin-top: rem-calc(16);
      }
    }

    .modal-body {
      padding-top: 0 !important;

      & .camera-switch {
        left: 45%; // ngx-webcam button realign
      }

      & .attachment-scanner-copyright {
        font-size: map-get($font-sizes, 8);
      }

      & .attachment-scanner-camera-container {
        max-width: 100vw;
        max-height: 70vh;
      }

      & .uploader-scanner-icon-container {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        min-height: 45vh;
      }

      & .uploader-scanner-icon {
        padding-top: rem-calc(16);
        text-align: center;
      }

      & .attachment-scanner-toolbar {
        display: none;
      }

      & .attachment-scanner-preview {
        min-width: 100%;
      }
    }
  }

  @media (max-width: $grid-breakpoint-sm) {
    .modal-header {
      display: none;
    }

    // frames
    .attachment-scanner-selfie-frame {
      background-size: 40%;
    }

    .modal-body {
      margin-bottom: rem-calc(-8);
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      // min-height: 50vh;

      .attachment-scanner-toolbar {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: rem-calc(60);
        background-color: rgb(0 0 0 / 0.5);

        .close,
        .btn-close {
          //margin-top: rem-calc(-20);
          //margin-right: -rem-calc(8);
          //color: $white !important;
          //opacity: 1;
          //font-weight: 500 !important;
          //font-size: 2.4rem !important;
          //line-height: 28px !important;
          position: absolute;
          top: rem-calc(22);
          right: rem-calc(8);
          min-width: rem-calc(45);
          padding: 0 !important;
          //outline: none;

          //&:hover {
          //  background: none;
          //}
        }
      }

      .attachment-scanner-action-row {
        position: absolute;
        bottom: 5%;
        z-index: 10;
        width: 100%;

        & button {
          background-color: transparent;
        }
      }

      .attachment-scanner-take-snapshot {
        position: absolute;
        bottom: 5%;
      }

      .camera-switch {
        left: rem-calc(40); // ngx-webcam icon position fix
        left: 47%;
        z-index: 2; // show above the scanner-toolbar
      }

      .attachment-scanner-copyright {
        display: none;
      }

      .attachment-scanner-preview {
        max-height: 100vh;
      }

      // status message
      & .uploader-scanner-icon-container {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        min-height: 25vh;
      }

      & .uploader-scanner-icon {
        padding-top: rem-calc(16);
        text-align: center;
      }
    }
  }

  @media (max-width: $grid-breakpoint-md) {
    .modal-header {
      display: none;
    }

    // frames
    .attachment-scanner-selfie-frame {
      display: none;
    }

    .modal-body {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    .attachment-scanner-toolbar {
      display: none;
    }

    .attachment-scanner-action-row {
      position: absolute;
      z-index: 10;
      width: 100%;

      & button {
        background-color: transparent;
      }
    }
  }

  @media (orientation: portrait) and (max-width: 768px) {
    // TODO: Refactor to function
    .attachment-scanner-camera-container {
      max-width: 100vw;
      max-height: 68vh;
    }
  }
  /* stylelint-disable media-feature-name-no-unknown */
  @media (orientation: portrait) and (max-width: 667px) and (min-device-pixel-ratio: 2) {
    // FFO-1506
    .attachment-scanner-camera-container {
      max-width: 100vw;
      max-height: 67vh !important;
    }
  }
  /* stylelint-enable media-feature-name-no-unknown */

  @media (orientation: landscape) and (max-width: 768px) {
    // TODO: Refactor to function
    .attachment-scanner-selfie-frame {
      display: none;
    }

    .attachment-scanner-camera-container {
      max-height: 100vh !important;
    }

    .attachment-scanner-preview {
      max-height: 90vh;
    }
  }

  @media (max-width: $grid-breakpoint-md) {
    .attachment-scanner-action-row {
      .attachment-scanner-btn {
        color: $white !important;
      }
    }
  }
}

// Error window
.attachment-scanner {
  @media (max-width: $grid-breakpoint-sm) {
    .attachment-scanner-error-window {
      min-width: 99vw;
    }
  }

  @media (max-width: $grid-breakpoint-sm) {
    .attachment-scanner-error-window {
      min-width: $modal-min-width;
    }
  }

  .attachment-scanner-error {
    max-width: 80%;
  }
}
