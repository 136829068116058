@import 'variables';

.custom-modal {
  position: relative;
  text-align: center;

  &.custom-modal-cyan {
    // todo: refactor with the same name
    background: $custom-modal-cyan-bg-color;
  }

  &.custom-modal-scan {
    &.modal-scan-front {
      .modal-body {
        padding: 4% 0;
      }
    }

    &.modal-scan-passport {
      .custom-modal-title-img {
        img {
          width: 100%;
          max-width: rem-calc(680);
          max-height: rem-calc(420);
          object-fit: cover;
        }
      }
    }

    &.modal-scan-recognition {
      .custom-modal-title-img {
        img {
          width: 100%;
          max-width: rem-calc(680);
          height: rem-calc(315);
          max-height: rem-calc(315);
          object-fit: cover;
        }
      }
    }

    .custom-modal-title-doc {
      color: $indigo;
      font-weight: bold;
      font-size: rem-calc(24);
      text-align: center;
      font-family: $font-family-monospace;
    }

    .modal-body {
      // max-width: 483px;
      max-width: rem-calc(650);
      padding: 7% 0;
    }

    .custom-modal-scan-info {
      margin-bottom: rem-calc(58);
      padding: 0 rem-calc(20);
      font-weight: $font-weight-normal;
      font-size: rem-calc(10);
    }

    .custom-modal-scan-button {
      display: flex;
      justify-content: center;
    }
  }

  &.custom-modal-scan-document {
    .mat-dialog-content {
      margin: 0 -rem-calc(24) !important;
      padding: 0 rem-calc(24) !important;
    }

    .custom-modal-scan-frame {
      display: flex;
      justify-content: center;

      .custom-modal-scan-frame-img {
        position: absolute;
        z-index: 10;
        width: 77%;
        margin-top: 10%;
      }

      @media (max-width: $grid-breakpoint-sm){
        .custom-modal-scan-frame-img {
          display: none !important;
        }
      }
    }

    .custom-modal-scan-header {
      position: absolute;
      right: 0;
      left: 0;
      z-index: 1;
      display: flex;
      justify-content: center;
      min-height: rem-calc(60);
      background: $custom-modal-scan-header-bg;
    }

    .custom-modal-scan-footer {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: center;
      width: 100%;
      padding: rem-calc(10);

      /* stylelint-disable */
      .mat-button.mat-accent.mat-button-icon {
        height: rem-calc(48) !important;
        padding: 0 !important;
      }
      /* stylelint-enable */
    }

    /* stylelint-disable */
    .mat-button.mat-accent,
    .mat-icon-button.mat-accent,
    .mat-stroked-button.mat-accent {
      color: $custom-modal-scan-btn-mat-accent-color;
      font-size: $custom-modal-scan-btn-mat-accent-font-size !important;
    }

    /* stylelint-enable */

    .mat-button-base.mat-raised-button {
      height: rem-calc(48);
      padding: 0 rem-calc(10) !important;
    }

    .webcam-wrapper {
      display: block;
      font-size: 0;
    }
  }
}
