@import 'variables';

.card-group {
  align-items: stretch;
  justify-content: flex-start;
  min-height: 125px;

  .card-group-item {
    display: block;
    width: 100%;
    height: $card-group-item-height;
    margin-bottom: 0;
    padding: rem-calc(16) rem-calc(16);
    color: $custom-card-group-item-color;
    text-align: center;
    border-radius: $card-group-border-radius;
    box-shadow: $card-group-box-shadow-active;
    cursor: pointer;
    border: none;
    background-color: $card-group-box-bg;


    &:disabled {
      opacity: $card-group-opacity;
    }

    &:not(:disabled):hover {
      color: $card-group-hover-color;
      background: $card-group-hover-bg;
      box-shadow: $card-group-box-shadow-selected !important;
    }

    &:disabled {
      box-shadow: $card-group-box-shadow-disabled;

      * {
        opacity: 1 !important;
      }

      &.btn {
        &:hover {
          box-shadow: $card-group-box-shadow-disabled !important;
        }
      }
    }

    &.selected {
      color: $card-group-selected-color;
      background: $card-group-selected-bg;
      box-shadow: $card-group-box-shadow-selected !important;

      &:not(:disabled):hover {
        color: $card-group-selected-hover-color;
        background: $card-group-selected-hover-bg;
        box-shadow: $card-group-box-shadow-selected !important;
      }
    }
  }

  .card-title {
    font-size: $card-group-card-title-font-size;
    text-transform: $card-group-card-title-text-transform;
  }

  .card-group-block {
    min-width: rem-calc(135);
    margin-bottom: map-get($spacers, 5);

    .card-group-icon {
      margin-bottom: rem-calc(10);
    }

    [class*='ff-'] {
      font-size: rem-calc(78);
      line-height: rem-calc(58);

      &:before {
        line-height: rem-calc(55);
      }
    }
  }

  &.card-group-partner {
    align-items: baseline;
    min-height: auto;

    .card-group-block {
      min-width: 33.3333% !important;
    }


    .card-group-item {
      display: flex;
      align-items: center;
      justify-content: center;
      height: fit-content;
      height: 100%;
      min-height: rem-calc(55);
    }

    .card-title {
      text-transform: none;
    }
  }
}

@media (max-width: $grid-breakpoint-lg) {
  .card-group {
    .card-group-item {
      height: auto;
      padding: rem-calc(16) rem-calc(8);
    }

    .card-title {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .card-group-block {
      min-width: auto;

      [class*='ff-'] {
        display: block;
        font-size: rem-calc(50);
        line-height: rem-calc(50);

        &:before {
          line-height: 0;
        }
      }
    }
  }
}

[data-theme="dark"] .card-group-item {
  border: 1px solid $secondary;
}
