@import "/src/styles/variables";

$component-step-title-font-size: rem-calc(27) !default;
$component-step-title-color: $text-muted !default;
$component-step-title-font-family: $font-family-monospace !default;
$component-step-title-font-weight: $font-weight-bold !default;
$component-step-title-icon-font-size: map-get($font-sizes, 3) !default;
$component-step-title-icon-color: $component-step-title-color !default;
$component-step-title-icon-line-height: $font-size-lg !default;
$component-step-title-icon-font-weight: $component-step-title-font-weight !default;
$component-step-title-icon-font-family: $font-family-monospace !default;
$component-step-photo-border-radius: $border-radius !default;
$component-step-photo-width: rem-calc(167) !default;
$component-step-photo-background: $white !default;
$component-step-photo-box-shadow: $box-shadow !default; //-4px 4px 6px rgba(0, 0, 0, .45)
