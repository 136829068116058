@use "src/styles/variables" as *;
@use "src/styles/functions/rem-calc" as *;

/* stylelint-disable selector-max-specificity */
.send-money-summary {
  position: relative;
  max-width: 490px;
  width: 100%;
  margin-top: 3.5rem;
  padding: map-get($spacers, 7);
  background-color: $body-bg;
  border: $border-width solid transparent;
  border-radius: $border-radius;
  box-shadow: $box-shadow-sm;

  & .send-money-summary-actions {
    position: absolute;
    top: rem-calc(-55);
    right: 0;
    display: flex;
  }

  .send-money-summary-label {
    color: $body-color;
    font-weight: $font-weight-bold;
    font-size: map-get($font-sizes, 6);
  }

  .send-money-summary-code {
    display: inline-block;
    border-bottom: calc(#{$border-width} * 2) solid $primary;
  }

  .col-receiver .send-money-summary-receiver-name,
  .col-remitter .send-money-summary-remitter-name,
  .col-remitter .send-money-summary-applicant-name {
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

// Basic container styling
.send-money-summary {
  & .send-money-summary-header {
    margin-bottom: map-get($spacers, 4);
    padding: map-get($spacers, 3) map-get($spacers, 5);
    font-weight: $font-weight-bold;
    background: $body-tertiary-bg;
    border-radius: $border-radius $border-radius 0 0;
  }

  & .send-money-summary-info {
    padding: 1rem 0;
    border-bottom: $border-width solid $border-color;

    &:last-child {
      border-bottom: none;
    }
  }

  & .summary-payment {
    padding-top: 0 !important;
  }

  & .summary-locations {
    button {
      padding-top: 0 !important;
    }

    & .grid.locations-grid {
      font-size: map-get($font-sizes, 8);

      // Grid row defaults
      & .grid-row {
        // Grid header
        & .grid-row {
          &.grid-header {
            margin-bottom: rem-calc(8);
            text-align: start;

            // redefine default values
            .col-actions {
              color: $secondary;
            }
          }
        }

        &.grid-header {
          & .grid-row-item {
            display: flex;
          }
        }

        // Grid row item
        &.grid-content {
          & .grid-row-item {
            // redefine default values
            display: flex;
            flex: 0 0 100%; // ffo-1136
            padding-top: map-get($spacers, 1);
            padding-bottom: map-get($spacers, 1);

            & .col-address {
              padding-right: map-get($spacers, 3);
            }
          }
        }
      }

      & .grid-col {
        padding-right: map-get($spacers, 3);
      }
    }
  }
}
