@import "/src/styles/variables";

$direction-list-grid-header-font-size: $font-size-sm !default;
$direction-list-grid-content-border-bottom: $border-width solid $border-color !default;
$direction-list-grid-content-hover-bg: $primary!default;

$direction-list-grid-col-country-font-size: calc(#{$font-size-base} * 1.1429) !default;
$direction-list-grid-col-country-font-weight: $font-weight-bold !default;
$direction-list-grid-col-country-font-family: $font-family-sans-serif;

$direction-list-grid-col-payout-method-font-family: $font-family-sans-serif !default;
$direction-list-grid-col-payout-method-font-size: calc(#{$font-size-base} * 1.2429) !default;
$direction-list-grid-col-payout-method-color: $text-muted !default;
$direction-list-grid-col-payout-method-icon-font-size: $font-size-lg !default;

$direction-list-grid-col-payout-partner-color: $secondary !default;
