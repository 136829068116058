@import 'variables';

.card {
  padding: $custom-card-padding;
  background-color: $custom-card-bg-color;
  box-shadow: $custom-card-box-shadow;
  border-radius: $custom-card-border-radius;
  border: 1px solid $light;
  overflow: hidden;
  @media (max-width: $grid-breakpoint-sm) {
    padding: rem-calc(10);
  }


  .card-header {
    margin: rem-calc(-17.6) rem-calc(-17.6) rem-calc(11.2) rem-calc(-17.6);
    padding: rem-calc(6.4) rem-calc(16);
    font-weight: $custom-card-header-font-weight;
    background: $custom-card-background;
    border-top-left-radius: $custom-card-border-radius;
    border-top-right-radius: $custom-card-border-radius;

    &-bold {
      color: $custom-card-bold-color;
      font-weight: $font-weight-bold;
    }
    &-title {
      @media (max-width: $grid-breakpoint-sm) {
        font-size: map-get($font-sizes, 5);
      }
    }
  }
}
