@import 'variables';

.no-results {
  // @extend .row
  //--#{$variable-prefix}gutter-x: #{$grid-gutter-width};
  //--#{$variable-prefix}gutter-y: 0;
  //display: flex;
  //flex-wrap: wrap;
  //margin-right: calc(var(--#{$variable-prefix}gutter-x) / -2); // stylelint-disable-line function-disallowed-list
  //margin-left: calc(var(--#{$variable-prefix}gutter-x) / -2); // stylelint-disable-line function-disallowed-list

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__image {
    max-width: rem-calc(286);
    min-height: 166px;
  }

  &__copyright {
    margin-top: map-get($spacers, 3);
    font-weight: $make-new-transfer-copyright-font-weight;
    font-size: $make-new-transfer-copyright-font-size;
    font-family: $make-new-transfer-copyright-font-family;
  }
}
