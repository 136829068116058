@import "/src/styles/variables";

$security-title-font-weight: $font-weight-bold !default;
$security-title-font-size: map-get($font-sizes, 4) !default;
$security-title-color: $gray-600 !default;
$security-info-font-weight: $font-weight-normal !default;
$security-info-font-size: map-get($font-sizes, 7) !default;
$security-info-color: $gray-600 !default;
$security-icon-status-before-font-size: map-get($font-sizes, 4) !default;
$security-icon-status-before-color: $green !default;
$security-icon-mat-expansion-panel-header-title-font-size: map-get($font-sizes, 2) !default;
$security-security-device-status-color: $secondary !default;
$security-security-device-status-font-size: map-get($font-sizes, 6) !default;
$security-security-device-icon-status-before-color: $teal !default;
$security-security-device-icon-status-before-font-size: map-get($font-sizes, 4) !default;

$security-security-device-title-font-weight: $font-weight-bold !default;
$security-security-device-title-font-size: map-get($font-sizes, 3) !default;

$security-security-device-place-font-weight: $font-weight-normal !default;
$security-security-device-place-font-size: map-get($font-sizes, 5) !default;
$security-security-device-place-color: $body-tertiary-color !default;

$security-security-device-sign-time-font-weight: $font-weight-normal !default;
$security-security-device-sign-time-font-size: map-get($font-sizes, 6) !default;

$security-security-device-sign-browser-font-weight: $font-weight-bold !default;
$security-security-device-sign-browser-font-size: map-get($font-sizes, 6) !default;

$security-security-device-ip-font-weight: $font-weight-bold !default;
$security-security-device-ip-font-size: map-get($font-sizes, 6) !default;
$security-security-device-ip-color: $body-tertiary-color !default;

$security-security-device-link-font-weight: $font-weight-normal !default;
$security-security-device-ip-font-size: map-get($font-sizes, 6) !default;
$security-security-device-ip-color: $body-tertiary-color !default;

$security-security-device-link-color: $secondary !default;
$security-security-device-font-size: map-get($font-sizes, 6) !default;
$security-security-device-hover-decoration: underline !default;

$password-strength-line-low-color: $danger !default;
$password-strength-line-middle-color: $warning !default;
$password-strength-line-high-color: $success !default;
