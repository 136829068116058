@import 'variables';

/* stylelint-disable selector-max-specificity */
.transaction-history {
  &__row {
    --ff-gutter-x: var(--ff-gutter-x);
    --ff-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 100%;

    .grid {
      width: 100%;
    }
  }

  &__filter-toggle {
    display: flex;
    justify-content: flex-end;
  }

  &__info {
    margin-bottom: map-get($spacers, 5);
  }

  @media (max-width: $grid-breakpoint-sm) {
    &__filter-badge {
      order: 2;
    }

    &__filters {
      .col-period {
        order: 1;
      }

      .col-issue-date {
        order: 2;
      }

      .col-expire-date {
        order: 3;
      }

      .col-reference-number {
        order: 4;
      }

      .col-city {
        order: 5;
      }

      .col-receiver {
        order: 6;
      }
    }
  }
}

// Grid default styles
.transaction-history {
  // Grid root

  // Grid row
  & .grid {
    & .grid-row {
      --ff-gutter-x: var(--ff-gutter-x);
      --ff-gutter-y: 0;
      display: flex;
      flex-wrap: wrap;
      margin-right: calc(var(--ff-gutter-x) / -2); // stylelint-disable-line function-disallowed-list
      margin-left: calc(var(--ff-gutter-x) / -2); // stylelint-disable-line function-disallowed-list
    }
  }

  // Grid header
  & .grid {
    & .grid-header {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      padding-top: rem-calc(48);
      padding-bottom: rem-calc(48);
      color: $transaction-history-grid-header-color;
      font-size: $transaction-history-grid-font-size;
      border-top: $transaction-history-grid-border-top;

      & .col {
        padding-right: 0;
      }
    }
  }

  // Grid content
  & .grid {
    & .grid-content {
      padding-top: rem-calc(16);
      padding-bottom: rem-calc(16);
      border-bottom: $border-width solid $border-color;

      & .col {
        padding-right: 0;
      }

      .grid-column-amount {
        font-size: $transaction-history-grid-column-amount-font-size;
      }
    }
  }

  // Grid columns default style
  & .grid .grid-row {
    & .grid-column {
      &.grid-column-reference {
        font-size: $transaction-history-grid-column-reference-font-size;
      }

      &.grid-column-status {
        & .badge {
          padding-right: rem-calc(8);
          padding-left: rem-calc(8);
          white-space: inherit;
          @media (max-width: $grid-breakpoint-lg) {
            white-space: nowrap;
          }
          @media (max-width: $grid-breakpoint-sm) {
            white-space: inherit;
          }
        }
      }

      &.grid-column-actions {
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-start;
        justify-content: flex-end;

        [class*='ff-'] {
          margin-bottom: 0;
          font-size: $transaction-history-grid-column-actions-font-size;
          line-height: $transaction-history-grid-column-actions-font-size;

          &:before {
            line-height: 0;
          }
        }

        & .btn {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
          font-size: $transaction-history-grid-column-actions-font-size;
        }
      }

      &.grid-column-collapse {
        padding-top: rem-calc(8);
        padding-bottom: rem-calc(8);
        flex: 0 0 100%;
      }
    }

    .grid-column-receiver {
      text-transform: $transaction-history-grid-column-receiver-text-transform;
      overflow: hidden;
      text-overflow: ellipsis;

      &-name {
        color: $transaction-history-grid-column-receiver-text-color;
      }
    }
  }
}

// Grid dashboard styling
.transaction-history {
  &.transaction-history-dashboard {
    height: 100%;

    .grid {
      border-top: none;
    }

    .grid-column {
      flex-basis: auto;
    }

    .grid-content {
      padding-bottom: 0 !important;
      border: none !important;
    }

    .grid-column-date {
      order: 1;
      margin-bottom: rem-calc(8);
      font-size: $transaction-history-grid-dashboard-column-date-font-size;
    }

    .grid-column-reference {
      order: 2;
      margin-bottom: rem-calc(8);
      font-size: $transaction-history-grid-dashboard-column-reference-font-size;
      word-break: break-all;
      text-align: end;
    }

    .column-top-actions {
      .btn {
        display: flex;
        padding: 0;
      }
    }

    .grid-column-receiver {
      order: 5;
      margin-bottom: rem-calc(8);
      font-weight: $transaction-history-grid-dashboard-column-receiver-font-weight;
      font-size: $transaction-history-grid-dashboard-column-receiver-font-size;
      font-family: $transaction-history-grid-dashboard-column-receiver-font-family;
    }

    .grid-column-direction {
      display: flex;
      align-items: center;
      order: 6;
      margin-bottom: rem-calc(8);
      font-size: $transaction-history-grid-dashboard-column-direction-font-size;
      font-family: $transaction-history-grid-dashboard-column-direction-font-family;

      .flag-icon {
        font-size: $transaction-history-grid-dashboard-column-direction-icon-font-size;
      }
    }

    .grid-column-amount {
      order: 7;
      font-size: $transaction-history-grid-dashboard-column-amount-font-size;
      text-align: start !important;
    }

    .grid-column-status {
      order: 8;
      text-align: start !important;
    }

    .grid-column-collapse {
      order: 9;
      padding-top: rem-calc(16);
    }

    .row-divider {
      order: 10;

      hr {
        margin: 0;
        margin-top: rem-calc(8) !important;
      }
    }

    .grid-column-actions {
      display: flex;
      justify-content: space-around;

      & .btn {
        display: flex;
        padding-bottom: rem-calc(8);
        font-size: $transaction-history-button-font-size;
        white-space: normal;
        text-align: start;
        align-items: center;

        &.btn-receipt:before {
          padding-right: rem-calc(8);
        }

        &.btn-eye-slash:before {
          padding-right: rem-calc(4.8);
        }

        &.btn-redo:before {
          padding-right: rem-calc(8);
        }
      }
    }
    @media (min-width: $grid-breakpoint-lg) {
      .grid-column-actions {
        flex-direction: column;
      }
      .grid-column-reference {
        order: 2 !important;
        text-align: start;

        &.col {
          padding-right: 0 !important;
        }
      }
    }

    @media (max-width: $grid-breakpoint-lg) {
      .btn-eye-slash,
      .btn-receipt,
      .attention-message {
        display: none;
      }

      .grid-column-direction {
        order: 6 !important;
      }

      .grid-column-amount {
        order: 5 !important;
      }

      .grid-column-date,
      .grid-column-direction,
      .grid-column-status {
        margin-bottom: 0 !important;
      }

      .grid-column-amount,
      .grid-column-direction,
      .grid-column-reference {
        max-width: 50% !important;
      }

      .grid-column-actions {
        justify-content: space-around;

        .mat-button {
          min-width: rem-calc(30);
        }
      }
    }

    // LG Only
    @media (max-width: $grid-breakpoint-lg) {
      .grid-column-amount {
        order: 6 !important;
      }

      .grid-column-direction {
        order: 7 !important;
      }
    }

    // LG--
    @media (max-width: $grid-breakpoint-lg) {
      .grid-column-payment,
      .grid-column-receiver,
      .grid-column-reference,
      .grid-column-status {
        margin-bottom: rem-calc(16);
      }

      .grid-column-amount {
        margin-bottom: 0 !important;
      }
    }

    .attention-message {
      margin-bottom: 0;
      font-size: $transaction-history-attention-message;
    }

    .transaction-history-empty {
      align-items: center;
      height: calc(100% - 56px);
      border: none !important;
    }

    .transaction-history-empty-text {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

// RESPONSIVE STYLING
// SM-
.transaction-history {
  &:not(.transaction-history-dashboard) {
    @media (max-width: $grid-breakpoint-lg) {
      .grid-column-actions {
        margin-bottom: rem-calc(8);
      }

      .grid-column-payment,
      .grid-column-receiver,
      .grid-column-amount {
        margin-bottom: rem-calc(8) !important;
      }

      .grid-column-amount,
      .grid-column-receiver .h4 {
        font-size: $transaction-history-grid-dashboard-column-amount-font-size-responsive-sm;
      }

      .grid-column-reference {
        font-size: $transaction-history-grid-dashboard-column-reference-font-size-responsive-sm;
      }
    }
  }
}

// MD-
.transaction-history {
  @media (max-width: $grid-breakpoint-xl) {
    .grid-header {
      display: none !important;
    }

    .grid {
      border-top: $border-width solid $border-color;

      .grid-column-date,
      .grid-column-direction,
      .grid-column-payment,
      .grid-column-receiver,
      .grid-column-amount,
      .grid-column-reference,
      .grid-column-status {
        margin-bottom: rem-calc(8);
      }

      .grid-column-amount,
      .grid-column-status {
        text-align: end;
      }
    }

    .grid-column-actions {
      order: 1;
    }

    .grid-column-date {
      order: 2;
    }

    .grid-column-direction {
      order: 3;
    }

    .grid-column-payment {
      order: 4;
    }

    .grid-column-receiver {
      order: 5;
    }

    .grid-column-amount {
      order: 6;
      padding-right: 0;
    }

    .grid-column-reference {
      order: 7;

      &.col {
        padding-right: rem-calc(15) !important;
      }
    }

    .grid-column-status {
      order: 8;
      padding-right: 0;
    }

    .grid-column-collapse {
      order: 9;
    }
  }
}

// BETWEEN SM AND LG
.transaction-history {
  @media (min-width: $grid-breakpoint-sm) and (max-width: $grid-breakpoint-xl) {
    .grid {
      //.grid-column-amount,
      .grid-column-status {
        max-width: none !important;
      }
    }
  }
}

// LG-
.transaction-history {
  @media (max-width: $grid-breakpoint-xl) {
    .transaction-history-summary,
    .transaction-history-pagination {
      justify-content: flex-end;
    }

    .transaction-history-summary {
      padding-right: 0;
    }
  }
}

// LG+
.transaction-history {
  @media (min-width: $grid-breakpoint-xl) {
    .grid {
      & .grid-column {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }
    }

    .col,
    .grid-column {
      &.grid-column-date {
        min-width: rem-calc(80);
        max-width: rem-calc(120);
      }

      &.grid-column-direction {
        min-width: rem-calc(155);
      }

      &.grid-column-payment {
        min-width: rem-calc(30);
        max-width: rem-calc(30);
      }

      &.grid-column-receiver {
        min-width: rem-calc(155);
      }

      &.grid-column-amount {
        min-width: rem-calc(70);
        max-width: rem-calc(120);
        //text-align: start;
      }

      &.grid-column-reference {
        min-width: rem-calc(100);
      }

      &.grid-column-status {
        display: flex;
        align-items: center;
        min-width: rem-calc(60);
        max-width: rem-calc(60);
        //text-align: start;
      }

      &.grid-column-actions {
        min-width: rem-calc(60);
      }

      &.grid-column-card {
        max-width: rem-calc(140);
      }
    }
  }
}
/* stylelint-enable selector-max-specificity */
