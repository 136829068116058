@import 'variables';

.component-step {
  padding: rem-calc(32) rem-calc(96);
}

.component-step-title {
  display: flex;
  color: $component-step-title-color;
  font-weight: $component-step-title-font-weight;
  font-size: $component-step-title-font-size;
  font-family: $component-step-title-font-family;
  align-items: center;
  text-align: center;

  &-icon-clear,
  .ff-icon {
    font-size: $component-step-title-font-size;
  }
}

.component-step-photo {
  position: relative;
  display: block;
  width: $component-step-photo-width;
  margin: auto;
  overflow: hidden;
  background: $component-step-photo-background;
  border-radius: $component-step-photo-border-radius;
  transition: box-shadow 400ms;

  &:hover {
    box-shadow: $component-step-photo-box-shadow;
    cursor: pointer;
    transition: box-shadow 400ms;
  }

  img {
    object-fit: cover;
  }
}

.component-step-title-icon {
  display: flex;
  align-items: center;
  margin-bottom: rem-calc(20);
  color: $component-step-title-icon-color;
  font-weight: $component-step-title-icon-font-weight;
  font-size: $component-step-title-icon-font-size;
  font-family: $component-step-title-icon-font-family;
}

// MD-
@media (max-width: $grid-breakpoint-md) {
  .component-step-title {
    font-size: calc(#{$font-size-base} * 1.2857);
  }
}

