@import '/src/styles/variables';

$card-group-box-shadow-selected: $box-shadow-inset !default;
$card-group-box-shadow-disabled: $box-shadow-sm !default;
$card-group-box-shadow-active: $box-shadow-sm !default;
$card-group-box-bg: $body-secondary-bg;
$card-group-item-height: rem-calc(144) !default;
$card-group-border-radius: $border-radius !default;
$card-group-color: $primary !default;
$card-group-selected-bg: $secondary !default;
$card-group-selected-color: $white !default;
$card-group-selected-hover-bg: $secondary !default;
$card-group-selected-hover-color: $white !default;
$card-group-hover-color: $white !default;
$card-group-hover-bg: $secondary !default;
$card-group-card-title-font-size: map-get($font-sizes, 5) !default;
$card-group-card-title-text-transform: uppercase !default;
$custom-card-group-item-color: $secondary !default;
$card-group-opacity: $btn-disabled-opacity;
