@import 'variables';

// Basic styling
.direction-list-grid {
  .col-country {
    display: flex;
    align-items: center;

    & .country-name {
      font-weight: $direction-list-grid-col-country-font-weight;
      font-size: $direction-list-grid-col-country-font-size;
      font-family: $direction-list-grid-col-country-font-family;
    }
  }

  .col-payout-method {
    display: flex;
    align-items: center;
    color: $direction-list-grid-col-payout-method-color;
    font-size: $direction-list-grid-col-payout-method-font-size;
    font-family: $direction-list-grid-col-payout-method-font-family;

    & .ff-icon {
      font-size: $direction-list-grid-col-payout-method-icon-font-size;
    }
  }

  .col-payout-partner {
    display: flex;
    align-items: center;
    color: $direction-list-grid-col-payout-partner-color;
  }
}

// Grid styling
.direction-list-grid {

  // Grid row
  & .grid {
    & .grid-row {
      display: flex;
      flex-wrap: wrap;

    }
  }

  // Grid header
  & .grid {
    & .grid-header {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      font-size: $direction-list-grid-header-font-size;
    }
  }
}

// MD -
@media (max-width: $grid-breakpoint-md) {
  .direction-list-grid {
    .direction-list-grid-row {
      margin-bottom: rem-calc(64) !important;
    }

    .grid-header-mobile {
      display: flex !important;
    }

    .grid-header-desktop {
      display: none !important;
    }

    .col-country,
    .col-payout-method {
      margin-bottom: rem-calc(16);
    }

    .col-payout-method {
      justify-content: flex-end;
    }
  }
}

// MD+
@media (min-width: $grid-breakpoint-md) {
  .direction-list-grid {
    .direction-list-grid-row {
      margin-bottom: rem-calc(16) !important;
    }

    .grid-header-desktop {
      display: flex !important;
    }

    .grid-header-mobile {
      display: none !important;
    }
  }
}
