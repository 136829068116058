@import 'variables';

.custom-row {
  max-width: rem-calc(685);
  margin: auto;
}

.custom-row-selfie {
  max-width: rem-calc(320);
  margin: auto;
}
