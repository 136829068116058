@import "/src/styles/variables";

$attachment-list-margin-bottom: rem-calc(16) !default;
$attachment-list-font-size: map-get($font-sizes, 3) !default;
$attachment-list-font-weight: $font-weight-normal !default;
$attachment-list-item-title-font-weight: $font-weight-bold !default;
$attachment-list-item-title-font-weight: $font-weight-bold !default;
$attachment-list-item-title-color: $gray-300 !default;
$attachment-list-icon-font-size: map-get($font-sizes, 4) !default;
$attachment-list-icon-color: $gray-300 !default;
$attachment-list-icon-hover-color: $gray-700 !default;
$attachment-list-icon-info-font-size: map-get($font-sizes, 3) !default;
$attachment-list-attachment-item-font-size: map-get($font-sizes, 4) !default;
$attachment-list-attachment-item-font-weight: $font-weight-bold !default;
$attachment-list-attachment-item-icon-font-size: rem-calc(35) !default;
$attachment-list-attachment-item-span-color: $body-color !default;
$attachment-list-warning-text-color: $warning !default;
$attachment-list-warning-icon-color: $warning !default;
