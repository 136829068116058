@import "/src/styles/variables";

$custom-modal-cyan-bg-color: $cyan !default;
$custom-modal-scan-header-bg: rgb(0 0 0 / 0.5) !default;
$custom-modal-scan-header-bg: rgb(0 0 0 / 0.5) !default;
$custom-modal-scan-btn-mat-accent-color: $white !default;
$custom-modal-scan-btn-mat-accent-font-size: rem-calc(30) !default;
$custom-modal-scan-header-bg: rgb(0 0 0 / 0.5) !default;
$custom-modal-scan-document-finish-btn-mat-accent-color: $custom-modal-scan-btn-mat-accent-color !default;
$custom-modal-scan-document-finish-btn-mat-accent-font-size: $custom-modal-scan-btn-mat-accent-font-size !default;


