@import "/src/styles/variables";

$document-frame: "%3Csvg width='866' height='512' viewBox='0 0 866 512' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M45.1242 0.110352H4.80044C2.14923 0.110352 0 1.92622 0 4.16619V40.6685H4.80044L4.80044 4.16619L45.1242 4.16617V0.110352ZM820.876 4.16617H861.199V40.6685H866V4.16617C866 1.9262 863.851 0.110352 861.199 0.110352H820.876V4.16617ZM861.199 472.208H866V507.899C866 510.139 863.851 511.955 861.199 511.955H820.876V507.899H861.199V472.208ZM4.80044 507.899H45.1242L45.1242 511.955H4.80044C2.14923 511.955 0 510.139 0 507.899V472.208H4.80044V507.899Z' fill='white'/%3E%3Cpath d='M45.1242 0.110352H4.80044C2.14923 0.110352 0 1.92622 0 4.16619V40.6685H4.80044L4.80044 4.16619L45.1242 4.16617V0.110352ZM820.876 4.16617H861.199V40.6685H866V4.16617C866 1.9262 863.851 0.110352 861.199 0.110352H820.876V4.16617ZM861.199 472.208H866V507.899C866 510.139 863.851 511.955 861.199 511.955H820.876V507.899H861.199V472.208ZM4.80044 507.899H45.1242L45.1242 511.955H4.80044C2.14923 511.955 0 510.139 0 507.899V472.208H4.80044V507.899Z' stroke='white'/%3E%3C/svg%3E%0A" !default;
$modal-min-width: rem-calc(907) !default;

$attachment-scanner-btn-color: $secondary !default;
$attachment-scanner-toolbar-color: $white !default;
$attachment-scanner-btn-text-color: $white !default;
$attachment-scanner-face-frame: "%3Csvg width='225' height='287' viewBox='0 0 225 287' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M112.632 0H113.027C170.085 0 224.613 29.7979 224.534 108.813C224.534 169.353 209.282 224.624 170.401 257.174C150.644 273.685 132.073 286.107 113.027 286.107H112.474C93.4287 286.107 74.8574 273.685 55.1007 257.174C16.2985 224.624 0.967285 169.353 0.967285 108.813C0.967285 29.7979 55.4958 0 112.632 0ZM181.495 34.2915C160.212 16.5378 132.544 12.7916 113.061 12.7916H112.652C93.1699 12.7916 65.5019 16.5378 44.2188 34.2915C24.8184 50.4165 14.9954 75.0925 14.9954 107.587C14.9954 152.46 23.5905 213.132 64.847 247.581C79.9908 260.285 97.5903 273.315 112.652 273.315H113.143C128.287 273.315 145.805 260.285 161.03 247.581C202.123 213.132 210.718 152.46 210.718 107.587C210.718 75.0925 200.895 50.4165 181.495 34.2915Z' fill='white' fill-opacity='0.8'/%3E%3C/svg%3E%0A" !default;
$attachment-scanner-face-frame-bg-position: 50% 30% !default;
$attachment-scanner-document-frame-bg: url('data:image/svg+xml,' + $document-frame) !default;
$attachment-scanner-document-frame-bg-size: 85% !default;
