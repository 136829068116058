@import 'variables';

.security {
  .security-block {
    padding: rem-calc(10) 0;
  }

  .security-item {
    margin-bottom: rem-calc(20);
  }

  .security-title {
    margin-bottom: rem-calc(15);
    color: $security-title-color;
    font-weight: $security-title-font-weight;
    font-size: $security-title-font-size;
  }

  .security-info {
    max-width: rem-calc(650);
    margin-bottom: rem-calc(15);
    color: $security-info-color;
    font-weight: $security-info-font-weight;
    font-size: $security-info-font-size;
  }

  .security-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .security-input {
    width: 100%;
    max-width: rem-calc(328);
  }

  .security-icon-status:before {
    color: $security-icon-status-before-color;
    font-size: $security-icon-status-before-font-size;
  }

  .mat-expansion-panel .mat-expansion-panel-header-title {
    font-size: $security-icon-mat-expansion-panel-header-title-font-size;
  }

  .security-device {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: rem-calc(40);

    .security-device-img {
      min-width: rem-calc(230);
      margin-bottom: rem-calc(30);
      text-align: center;
    }

    .security-device-status {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-left: rem-calc(150);
      color: $security-security-device-status-color;
      font-size: $security-security-device-status-font-size;
    }

    .security-device-icon-status:before {
      color: $security-security-device-icon-status-before-color;
      font-size: $security-security-device-icon-status-before-font-size;
    }

    .security-device-content {
      width: 100%;

      .security-device-title {
        display: flex;
        align-items: center;
        margin-bottom: rem-calc(8);
        font-weight: $security-security-device-title-font-weight;
        font-size: $security-security-device-title-font-size;
      }

      .security-device-place {
        margin-bottom: rem-calc(8);
        color: $security-security-device-place-color;
        font-weight: $security-security-device-place-font-weight;
        font-size: $security-security-device-place-font-size;
      }

      .security-device-sign-time {
        margin-bottom: rem-calc(20);
        font-weight: $security-security-device-sign-time-font-weight;
        font-size: $security-security-device-sign-time-font-size;
      }

      .security-device-sign-browser {
        margin-bottom: rem-calc(8);
        font-weight: $security-security-device-sign-browser-font-weight;
        font-size: $security-security-device-sign-browser-font-size;
      }

      .security-device-ip {
        margin-bottom: rem-calc(8);
        color: $security-security-device-ip-color;
        font-weight: $security-security-device-ip-font-weight;
        font-size: $security-security-device-ip-font-size;
      }

      .security-device-link {
        color: $security-security-device-link-color;
        font-weight: $security-security-device-link-font-weight;
        font-size: $security-security-device-font-size;
        cursor: pointer;

        &:hover {
          text-decoration: $security-security-device-hover-decoration;
        }
      }
    }
  }
}

.password-strength-line {
  display: none;
  align-items: center;
  justify-content: space-between;

  &.low {
    display: flex;
    color: $password-strength-line-low-color;
  }

  &.middle {
    display: flex;
    color: $password-strength-line-middle-color;
  }

  &.height {
    display: flex;
    color: $password-strength-line-high-color;
  }
}

@media (max-width: $grid-breakpoint-lg) {
  .security {
    .security-device {
      .security-device-status {
        margin-left: rem-calc(30);
      }
    }
  }
}

@media (max-width: $grid-breakpoint-md) {
  .security {
    .security-device {
      .security-device-content .security-device-title {
        display: block;
      }

      .security-device-status {
        display: block;
        margin-top: rem-calc(8);
        margin-left: 0;
      }
    }
  }
}

@media (max-width: $grid-breakpoint-sm){
  .security {
    .security-device {
      display: block;

      .security-device-content .security-device-title {
        display: block;
      }

      .security-device-status {
        display: block;
        margin-top: rem-calc(8);
        margin-left: 0;
      }
    }
  }
}
