@import "/src/styles/variables";

$receiver-preview-avatar-size: rem-calc(102) !default;
$receiver-preview-avatar-size-sm: rem-calc(50) !default;
$receiver-preview-avatar-bg: $gray-300 !default;
$receiver-preview-avatar-border-radius: 50% !default;
$receiver-preview-avatar-transition: $transition-base !default;
$receiver-preview-avatar-font-family: $transition-base !default;
$receiver-preview-avatar-color: $white !default;
$receiver-preview-avatar-font-size: map-get($font-sizes, 5) !default;
$receiver-preview-avatar-name-font-size: calc(#{$font-size-base} * 2.5714) !default;
$receiver-preview-avatar-name-font-weight: $font-weight-bold !default;
$receiver-preview-avatar-name-color: $white !default;
$receiver-preview-avatar-username-color: $primary !default;
