@import "/src/styles/variables";

$transaction-history-summary-color: $gray-300 !default;
$transaction-history-empty-text-font-size: $font-size-lg !default;
$transaction-history-empty-text-icon-font-size: rem-calc(40) !default;
$transaction-history-empty-text-icon-color: currentcolor !default;
$transaction-history-empty-text-font-weight: $font-weight-bold !default;
$transaction-history-flag-box-shadow: $box-shadow-sm !default;
$transaction-history-grid-header-color: $text-muted !default;
$transaction-history-grid-border-top: map-get($border-widths, 1) solid $border-color !default;
$transaction-history-grid-font-size: map-get($font-sizes, 5) !default;
$transaction-history-attention-message: map-get($font-sizes, 8) !default;
$transaction-history-button-font-size: map-get($font-sizes, 6) !default;
$transaction-history-grid-column-font-size: null !default;
$transaction-history-grid-column-amount-font-size: map-get($font-sizes, 4) !default;
$transaction-history-grid-column-payment-icon-font-size: $font-size-lg !default;
$transaction-history-grid-column-payment-icon-color: $text-muted !default;
$transaction-history-grid-column-reference-font-size: $font-size-sm !default;
$transaction-history-grid-column-actions-font-size: map-get($font-sizes, 2) !default;
$transaction-history-grid-column-receiver-text-transform: capitalize !default;
$transaction-history-grid-column-receiver-text-color: $secondary;
$transaction-history-grid-column-receiver-text-color: $secondary;

$transaction-history-grid-dashboard-column-date-font-size: calc(#{$font-size-base} * 0.75) !default;
$transaction-history-grid-dashboard-column-reference-font-size: calc(#{$font-size-base} * 0.625) !default;
$transaction-history-grid-dashboard-column-receiver-font-size: map-get($font-sizes, 4) !default;
$transaction-history-grid-dashboard-column-receiver-font-weight: $font-weight-bold !default;
$transaction-history-grid-dashboard-column-receiver-font-family: $font-family-monospace !default;
$transaction-history-grid-dashboard-column-direction-font-size: calc(#{$font-size-base} * 0.75) !default;
$transaction-history-grid-dashboard-column-direction-font-family: $font-family-sans-serif !default;
$transaction-history-grid-dashboard-column-direction-icon-font-size: map-get($font-sizes, 5) !default;
$transaction-history-grid-dashboard-column-amount-font-size: map-get($font-sizes, 6) !default;
$transaction-history-grid-dashboard-column-amount-font-size-responsive-sm: map-get($font-sizes, 5) !default;
$transaction-history-grid-dashboard-column-reference-font-size-responsive-sm: map-get($font-sizes, 6) !default;
