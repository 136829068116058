@import 'variables';
/* stylelint-disable selector-max-specificity */
.transfer-details {
  & .heading-display-2 {
    // TODO: refactor, use class from heading-display helpers
    font-family: $font-family-monospace;
  }

  .select-receiver-illustration {

    &__img {
      max-width: rem-calc(322);
      width: 100%;
      height: auto;
    }
  }

  .component-step-title-icon {
    &.primary-color {
      & .component-step-title {
        color: $transfer-component-step-title-color;
      }
    }

    &.secondary-color {
      & .component-step-title {
        color: $transfer-component-step-title-color;
      }
    }
  }
}

// Dashboard styling
.transfer-details {
  & .transfer-details-dashboard {
    .send-money-illustration {
      &__img {
        max-width: 100%;
        width: 100%;
        height: auto;
      }
    }

    @media (max-width: $grid-breakpoint-md) {
      .send-money-illustration {
        display: none;
      }
    }

    .component-step-title-icon {
      margin-bottom: rem-calc(8);

      .component-step-title {
        display: flex;
        color: $transfer-details-step-title-color;
        font-size: $transfer-details-step-title-font-size;
        font-weight: $font-weight-bold;
        align-items: center;
      }
    }

    & .card-group-item {
      height: auto;
    }

    & .card-group-block {
      min-width: auto;

      [class*='ff-'] {
        display: block;
        margin-bottom: 0;
        font-size: rem-calc(60);
        line-height: rem-calc(60);

        &:before {
          line-height: 0;
        }
      }
    }

    & .card-group-partner {
      & .card-group-item {
        height: 100%;
        min-height: inherit;
        padding-top: rem-calc(8);
        padding-bottom: rem-calc(8);
      }
    }
  }
}

// Custom breakpoints
@media (min-width: 1300px) and (max-width: 1700px) {
  .calculator-row {
    & .calculator-column {
      min-width: 100%;
    }
  }
}

/* stylelint-enable selector-max-specificity */
