@import 'variables';

// component-attachment-list,
.attachment-list {
  & + span {
    color: $attachment-list-attachment-item-span-color;
  }

  &.attachment-list-with-button {
    margin-bottom: $attachment-list-margin-bottom;

    .attachment-item {
      height: auto;
      font-weight: $attachment-list-font-weight;
      font-size: $attachment-list-font-size;

      &.attachment-item-title {
        color: $attachment-list-item-title-color;
        font-weight: $attachment-list-item-title-font-weight;
        font-size: $attachment-list-icon-font-size;
        font-family: $font-family-monospace;

        i {
          font-size: $attachment-list-icon-font-size;
        }

        .attachment-item-text {
          margin: 0 rem-calc(12) 0 rem-calc(6);
        }
      }
    }

    .attachment-icon-info {
      font-size: $attachment-list-icon-info-font-size;
      cursor: pointer;
    }
  }

  // TODO: I do not find this class

  // &.list-height-overflow {
  //   max-height: 4.5rem;
  //   overflow-y: auto;
  //   @extend .scroll-sm;
  // }

  .attachment-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: $attachment-list-attachment-item-font-weight;
    font-size: $attachment-list-attachment-item-font-size;

    .mat-button-icon {
      min-width: $attachment-list-attachment-item-icon-font-size !important;
    }
  }

  & .attachment-title {
    // width: 142px;
    width: rem-calc(185);
    @media (max-width: $grid-breakpoint-sm) {
      width: rem-calc(95);
    }
  }

  & span {
    display: block;
    width: auto;
    overflow: hidden;
    line-height: 2;
    white-space: nowrap;
    text-decoration: none;
    text-overflow: ellipsis;
    word-wrap: normal;
  }

  .mat-button-icon {
    color: $attachment-list-icon-color;
    font-size: $attachment-list-attachment-item-icon-font-size;

    &:hover {
      color: $attachment-list-icon-hover-color !important;
      background: transparent !important;
    }
  }

  .btn.btn-preview:hover:after,
  .btn-link:hover {
    color: $attachment-list-icon-hover-color;
  }
}
/* stylelint-disable */
.attachment-list {
  &.attachment-warning {
    .attachment-item.attachment-item-title {
      .attachment-item-text {
        color: $attachment-list-warning-text-color;
      }
    }

    .mat-button.mat-button-icon-attach,
    .mat-icon-button.mat-button-icon-attach,
    .mat-stroked-button.mat-button-icon-attach {
      color: $attachment-list-warning-text-color;
    }

    .mat-button-icon-attach .mat-button-focus-overlay,
    .mat-button-icon-attach .mat-button-focus-overlay,
    .mat-button-icon-attach .mat-button-focus-overlay {
      background-color: $attachment-list-warning-icon-color;
    }

    .attachment-info {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: rem-calc(20);
      color: $attachment-list-warning-text-color;
    }

    .custom-modal-scan-info {
      color: $attachment-list-warning-text-color;
    }

    .close,
    .btn-close {
      color: $attachment-list-warning-icon-color;
    }

    &.custom-modal.custom-modal-scan .close,
    &.custom-modal.custom-modal-scan .btn-close {
      color: $attachment-list-warning-icon-color;
    }
  }
}
/* stylelint-enable */
