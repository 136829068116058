@import 'variables';

.dashboard-card {
  margin-top: rem-calc(32);
  margin-bottom: rem-calc(32);
  padding-right: rem-calc(30) !important;
  padding-left: rem-calc(30) !important;

  &.history-empty {
    height: calc(100% - 55px);
  }

  &.mat-card {
    box-shadow: $dashboard-card-box-shadow !important;
  }

  .dashboard-card-heading {
    margin-bottom: rem-calc(16);
  }

  .dashboard-card-body {
    height: calc(100% - 56px);
  }

  .dashboard-card-name {
    color: $dashboard-card-name-color;
    font-weight: $dashboard-card-name-font-weight;
    font-size: $dashboard-card-name-font-size;
    font-family: $dashboard-card-font-family;
    line-height: $dashboard-card-name-line-height;
  }

  .dashboard-card-action {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    & .btn-link {
      padding: 0;
      color: $dashboard-card-btn-link-color;
    }
  }
}

// MD --
@media (max-width: $grid-breakpoint-lg) {
  .dashboard-card {
    padding-right: rem-calc(20) !important;
    padding-left: rem-calc(20) !important;

    .dashboard-card-name {
      font-size: map-get($font-sizes, 4) !important;
    }

    .dashboard-card-action {
      .btn {
        font-size: map-get($font-sizes, 7) !important;
      }
    }
  }
}
